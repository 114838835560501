import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CguViewWrapper } from 'ToolboxComponents/webapp/pages/cgu-view/cgu-view.styles';

const CguView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const { owner, email } = infos;
  return (
    <CguViewWrapper theme={theme} className='container'>
      <h2>CGU - Clause Données personnelles</h2>

      <br />

      <p>
        Lors de sa navigation sur la Webapp et/ou sur l’application mobile, l’Utilisateur est informé que des données relatives à sa connexion et à sa navigation sont traitées par {owner} et l’administrateur de la Plateforme, notamment par le biais de cookies et des formulaires qu’ils ont remplis. A titre d’exemple, en créant son Compte, l’Utilisateur complète des formulaires et indique des informations sur son identité, sa situation familiale, ses coordonnées, sa qualité, sa photographie, ses envies, sa géolocalisation et le type de transport utilisé. Les informations avec un astérisque sont obligatoires pour pouvoir créer un Compte. Ces informations sont purement déclaratives et l’Utilisateur est seul responsable de la qualité de leur saisie. En d’autres termes, aucune vérification n’est opérée par {owner}. Par ailleurs les données collectées par {owner} lors de la navigation de l’Utilisateur sont utilisées pour améliorer son expérience et plus particulièrement traiter les créations de compte, la saisie de données, le bon fonctionnement de la Webapp et/ou de l’application mobile, la réalisation de statistiques et un profilage non nominatif. Il est possible de désactiver les cookies en suivant les instructions fournies par les navigateurs toutefois cela peut ralentir ou limiter votre expérience sur la Webapp et/ou sur l’application mobile.
      </p>

      <br />

      <p>
        Les données des Utilisateurs sont conservées confidentiellement par {owner}, sont exclusivement traitées par {owner} et la société Agitateurs de Destinations Numériques et, sauf opposition de leur part, leurs partenaires.
      </p>

      <br />

      <p>
        Les Utilisateurs peuvent à tout moment se désinscrire et supprimer leurs données en accédant à leur Compte. Leurs données sont conservées le temps de la relation contractuelle. Au-delà, les données ne pourront être conservées que pour établir la preuve d’un droit ou d’un contrat, ou au titre du respect d’une obligation légale et conformément à la réglementation en vigueur. Les informations collectées par les cookies seront quant à elles conservées pendant treize (13) mois, à l’issue de cette période ces données seront supprimées.
      </p>

      <br />

      <p>
        Conformément aux dispositions légales relatives à la protection des données à caractère personnel, l’Utilisateur dispose notamment d'un droit d'accès, de rectification et d'opposition pour motif légitime s'agissant des informations le concernant et du droit d’organiser le sort de ses données post-mortem. Ces droits s’exercent par email à <a href={`mailto:${email}`}>{email}</a> en précisant dans l’objet « MES DONNEES » et en joignant la copie d’un justificatif d’identité. Pour en savoir plus consultez notre <Link to={'/confidentialite'}>Politique de confidentialité</Link>.
      </p>
    </CguViewWrapper>
  );
}

CguView.propTypes = {
  infos: PropTypes.object.isRequired,
}

export default CguView;
